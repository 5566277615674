import {t} from "i18next";
import React from "react";
import Language from "./Language";
import {Header} from "./Header";
import {Footer} from "./Footer";
import {Button, Card, CardBody, Divider, Input, Link} from "@nextui-org/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faCircleQuestion, faEye, faEyeSlash, faUser} from "@fortawesome/free-solid-svg-icons";
import {work} from "../../utils/defaultData";
import {faApple, faFacebook, faGoogle, faSpotify, faWeixin, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import axios from "axios";

const handleLogin = async (username, password) => {
    try {
        const response = await axios.post('https://example.com/api/login', { username, password });
        const { token } = response.data;
        localStorage.setItem('token', token); // Save the token to local storage
        console.log('Logged in successfully!');
    } catch (error) {
        console.error('Login failed:', error);
    }
};

const handleLogout = () => {
    localStorage.removeItem('token'); // Clear the token
    console.log('Logged out successfully!');
};

export function Login() {
    const [isVisible, setIsVisible] = React.useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);
    return (
        <div className="flex flex-col min-h-screen">
            <Header/>
            <div className="container mx-auto px-4 m-4 max-w-[700px] flex-1 min-h-72 mt-16">
                <div className="bg-white font-bold border-black rounded-lg ">
                    <Card>
                        <CardBody>
                            <div className="p-8">
                                <p className="font-bold text-2xl mb-8 ">{t("login")}</p>
                                <Input type="email" label="Email or Username" className="mb-4"/>
                                <Input
                                    label="Password"
                                    endContent={
                                        <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                            {isVisible ? (
                                                <FontAwesomeIcon icon={faEye}/>
                                            ) : (
                                                <FontAwesomeIcon icon={faEyeSlash}/>
                                            )}
                                        </button>
                                    }
                                    type={isVisible ? "text" : "password"}
                                    className="mb-4"
                                />
                                <div className="mb-4 text-right grid">
                                    <a className="text-sm">Forget Password</a>
                                    <a className="text-sm">Sign up for a new account</a>
                                </div>
                                <Button size="md" className="w-full bg-black text-white font-bold" radius="full">
                                    Login
                                </Button>
                                <Divider className="my-8"/>
                                <div className="flex gap-4 justify-center">
                                    <Button isIconOnly radius="full" href="/login"
                                            className="bg-[#1877F2] text-white font-bold shadow hover:border-2 border-black">
                                        <FontAwesomeIcon icon={faFacebook}/>
                                    </Button>
                                    <Button isIconOnly radius="full"
                                            className="bg-white font-bold shadow hover:border-2 border-black">
                                        <FontAwesomeIcon icon={faGoogle}/>
                                    </Button>
                                    <Button isIconOnly radius="full"
                                            className="bg-foreground-200 text-black font-bold shadow hover:border-2 border-black">
                                        <FontAwesomeIcon icon={faXTwitter}/>
                                    </Button>
                                    <Button isIconOnly radius="full"
                                            className="bg-[#1DB954] text-white font-bold shadow hover:border-2 border-black">
                                        <FontAwesomeIcon icon={faSpotify}/>
                                    </Button>
                                    <Button isIconOnly radius="full"
                                            className="bg-[#1AAD19] text-white font-bold shadow hover:border-2 border-black">
                                        <FontAwesomeIcon icon={faWeixin}/>
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>

            </div>
            <Footer className="mt-auto"/>
        </div>
    )
}
