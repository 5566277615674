import React, {useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBackward,
    faBars, faForward,
    faGlobe,
    faHeart,
    faMagnifyingGlass,
    faMusic, faPause, faPlay, faPlus, faQuestion, faRepeat, faShuffle,
    faUser, faX,
} from "@fortawesome/free-solid-svg-icons";
import "./css/Header.css"
import {
    Link,
    Button,
    Image,
    Input,
    ModalContent,
    Modal,
    ModalBody,
    useDisclosure,
    Slider,
    Divider
} from "@nextui-org/react";
import {work} from "../../utils/defaultData";
import {faDiscord, faTwitter, faXTwitter} from "@fortawesome/free-brands-svg-icons";


function MusicPlayer({ file, artists, coverImageUrl, songTitle, songNumber}) {
    const { isOpen: isOpenModal1, onOpen: onOpenModal1, onOpenChange: onOpenChangeModal1 } = useDisclosure();
    const { isOpen: isOpenModal2, onOpen: onOpenModal2, onOpenChange: onOpenChangeModal2 } = useDisclosure();
    const [liked, setLiked] = React.useState(false);
    const audioRef = useRef(new Audio(file));
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [value, setValue] = useState(0); // Slider value

    const togglePlayPause = () => {
        if (!isPlaying) {
            // Attempt to play and catch any errors
            audioRef.current.play().then(() => {
                setIsPlaying(true);
            }).catch(error => console.error("Error attempting to play audio:", error));
        } else {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    function formatSeconds(seconds) {
        // Round the seconds to the nearest integer in case it's a floating-point number
        seconds = Math.round(seconds);

        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;

        // Pad the minutes and seconds with leading zeros if necessary
        minutes = String(minutes).padStart(2, '0');
        remainingSeconds = String(remainingSeconds).padStart(2, '0');

        return `${minutes}:${remainingSeconds}`;
    }

    const handleSliderChange = (newValue) => {
        setValue(newValue);
        if (audioRef.current) {
            audioRef.current.currentTime = newValue;
        }
    };

    audioRef.current.onloadedmetadata = () => {
        setDuration(Math.round(audioRef.current.duration));
    };


    audioRef.current.ontimeupdate = () => {
        setCurrentTime(Math.round(audioRef.current.currentTime));
    };

    return (
        <div>
            <Button onPress={onOpenModal1} isIconOnly radius="full" className={isPlaying ? "bg-white font-bold shadow border-2 border-green-500" : "bg-white font-bold shadow hover:border-2 border-black"} >
                <FontAwesomeIcon icon={faMusic}/>
            </Button>
            <Modal isOpen={isOpenModal1} hideCloseButton={true} onOpenChange={onOpenChangeModal1} className="min-h-96">
                <ModalContent>
                    {(onClose) => (
                        <div>
                            <div className="m-12">
                                <div
                                    className="items-center justify-center">
                                    <div
                                        className="m-auto">
                                        <Image
                                            draggable="false"
                                            alt="Album cover"
                                            className="object-cover"
                                            shadow="md"
                                            src={coverImageUrl}
                                        />
                                    </div>

                                    <div className="my-8">
                                        <div className="flex justify-between items-start">
                                            <div className="flex flex-col gap-0">
                                                <p className="text-large font-bold">{songTitle}</p>
                                                <p className="text-small text-foreground/80">By
                                                    {artists.map((item, index) => (
                                                        <Link color="foreground"
                                                              className="text-small ml-1">{item.name}</Link>
                                                    ))}
                                                </p>
                                            </div>
                                            <div>
                                                <Button
                                                    isIconOnly
                                                    className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                                                    radius="full"
                                                    variant="light"
                                                    onPress={onOpenModal2}
                                                >
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                </Button>
                                                <Modal isOpen={isOpenModal2} hideCloseButton={true} onOpenChange={onOpenChangeModal2} className="min-h-96">
                                                    <ModalContent className="px-4 py-8">
                                                        <Link isBlock href="#"
                                                              className="justify-center overflow-hidden rounded-full min-w-20 font-bold text-slate-700 text-lg"
                                                              color="foreground">
                                                            <FontAwesomeIcon icon={faPlay} className="mr-2"/>Add To Queue
                                                        </Link>
                                                        <Divider className="my-4"/>
                                                        <Link isBlock href="#"
                                                              className="justify-center overflow-hidden rounded-full= min-w-20 font-bold text-slate-700 text-lg"
                                                              color="foreground">
                                                            Add a New Playlist
                                                        </Link>
                                                    </ModalContent>
                                                </Modal>
                                                <Button
                                                    isIconOnly
                                                    className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                                                    radius="full"
                                                    variant="light"
                                                    onPress={() => setLiked((v) => !v)}
                                                >
                                                    <FontAwesomeIcon icon={faHeart}/>
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="flex flex-col mt-3 gap-1">
                                            <Slider
                                                aria-label="Music progress"
                                                classNames={{
                                                    track: "bg-default-500/30",
                                                    thumb: "w-2 h-2 after:w-2 after:h-2 after:bg-foreground",
                                                }}
                                                color="foreground"
                                                maxValue={duration}
                                                value={currentTime}
                                                onChange={(value) => {
                                                    handleSliderChange(value);
                                                }}
                                                size="sm"
                                            />
                                            <div className="flex justify-between">
                                                <p className="text-small">{formatSeconds(currentTime)}</p>
                                                <p className="text-small text-foreground/50">{formatSeconds(duration)}</p>
                                            </div>
                                        </div>

                                        <div className="flex w-full items-center justify-center">
                                            <Button
                                                isIconOnly
                                                className="data-[hover]:bg-foreground/10"
                                                radius="full"
                                                variant="light"
                                            >
                                                <FontAwesomeIcon icon={faRepeat}
                                                                 className="text-black text-opacity-50"/>
                                            </Button>
                                            <Button
                                                isIconOnly
                                                className="data-[hover]:bg-foreground/10"
                                                radius="full"
                                                variant="light"
                                            >
                                                <FontAwesomeIcon icon={faBackward}/>
                                            </Button>
                                            <Button
                                                onClick={togglePlayPause}
                                                isIconOnly
                                                className="w-[50px] h-[50px] data-[hover]:bg-foreground/10"
                                                radius="full"
                                                variant="light"
                                            >
                                                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} className="text-2xl"/>
                                            </Button>
                                            <Button
                                                isIconOnly
                                                className="data-[hover]:bg-foreground/10"
                                                radius="full"
                                                variant="light"
                                            >
                                                <FontAwesomeIcon icon={faForward}/>
                                            </Button>
                                            <Button
                                                isIconOnly
                                                className="data-[hover]:bg-foreground/10"
                                                radius="full"
                                                variant="light"
                                            >
                                                <FontAwesomeIcon icon={faShuffle}
                                                                 className="text-black text-opacity-50"/>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}

export function Localization() {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    return(
        <div>
            <Button onPress={onOpen} isIconOnly radius="full"
                    className="bg-white font-bold shadow hover:border-2 border-black">
                <FontAwesomeIcon icon={faGlobe}/>
            </Button>
            <Modal isOpen={isOpen} hideCloseButton={true} onOpenChange={onOpenChange}>
                <ModalContent>
                    <div className="m-12 text-center">
                        <FontAwesomeIcon icon={faGlobe} className="mb-4"/>
                        <Divider className="mb-4"/>
                        <Link isBlock href="#"
                              className="justify-center overflow-hidden w-full rounded-full font-bold text-slate-700 text-lg"
                              color="foreground">
                            English
                        </Link>
                        <Link isBlock href="#"
                              className="justify-center overflow-hidden w-full rounded-full font-bold text-slate-700 text-lg"
                              color="foreground">
                            中文
                        </Link>
                        <Link isBlock href="#" isDisabled
                              className="justify-center overflow-hidden w-full rounded-full font-bold text-slate-700 text-lg"
                              color="foreground">
                            日本語
                        </Link>
                        <Link isBlock href="#" isDisabled
                              className="justify-center overflow-hidden w-full rounded-full font-bold text-slate-700 text-lg"
                              color="foreground">
                            한국어
                        </Link>
                        <Link isBlock href="#" isDisabled
                              className="justify-center overflow-hidden w-full rounded-full font-bold text-slate-700 text-lg"
                              color="foreground">
                            Deutsch
                        </Link>
                        <Link isBlock href="#" isDisabled
                              className="justify-center overflow-hidden w-full rounded-full font-bold text-slate-700 text-lg"
                              color="foreground">
                            Español
                        </Link>
                        <Link isBlock href="#" isDisabled
                              className="justify-center overflow-hidden w-full rounded-full font-bold text-slate-700 text-lg"
                              color="foreground">
                            Français
                        </Link>
                    </div>
                </ModalContent>
            </Modal>
        </div>
    )
}


export function Header() {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    return (
        <div>
            <div className="hidden lg:inline">
                <div className="grid grid-cols-3 m-4">
                    <div className="flex gap-2">
                        <Link href="/"><Image className="h-10 mr-4" src="/logo192.png"/></Link>
                        <Link isBlock href="#"
                              className="justify-center overflow-hidden rounded-full text-sm min-w-20 font-bold text-slate-700 "
                              color="foreground">
                            Art
                        </Link>
                        <Link isBlock href="#"
                              className="justify-center overflow-hidden rounded-full text-sm min-w-20 font-bold text-slate-700 "
                              color="foreground">
                            Spaces
                        </Link>
                    </div>
                    <div className="flex gap-2">
                        <Input
                            radius="full"
                            placeholder="Search your art"
                            labelPlacement="outside"
                            startContent={
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            }
                            classNames={{
                                input: [
                                    "placeholder:text-opacity-50",
                                ],
                            }}
                        />
                    </div>
                    <div className="flex gap-2 justify-end">
                        <MusicPlayer
                            file={work.content[0].file}
                            artists={work.artists}
                            coverImageUrl={work.coverImageUrl}
                            songTitle={work.content[0].title}
                            songNumber={work.detail.songNumber}
                        />
                        <Localization />
                        <Button as={Link} href="/login" radius="full" className="font-bold shadow bg-black text-white">
                            Get Started
                        </Button>
                    </div>
                </div>
            </div>
            <div className="lg:hidden">
                <div className="m-4">
                    <Link href="/"><Image className="h-10 mr-4" src="/logo192.png"/></Link>
                    <div className="float-right flex gap-2">
                        <MusicPlayer
                            file={work.content[0].file}
                            artists={work.artists}
                            coverImageUrl={work.coverImageUrl}
                            songTitle={work.content[0].title}
                            songNumber={work.detail.songNumber}
                        />
                        <Button isIconOnly radius="full" as={Link} href="/login"
                                className="bg-white font-bold shadow hover:border-2 border-black">
                            <FontAwesomeIcon icon={faUser}/>
                        </Button>
                        <Button isIconOnly radius="full"
                                className="bg-white font-bold shadow hover:border-2 border-black"
                                onClick={onOpenChange}>
                            <FontAwesomeIcon icon={faBars}/>
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                hideCloseButton>
                <ModalContent>
                    <ModalBody>
                        <div className="flex gap-2 mt-4 mx-4">
                            <Input
                                radius="full"
                                placeholder="Search your art"
                                labelPlacement="outside"
                                startContent={
                                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                }
                                classNames={{
                                    input: [
                                        "placeholder:text-opacity-50",
                                    ],
                                }}
                            />
                        </div>
                        <Divider className="my-4"/>
                        <Link isBlock href="#"
                              className="justify-center overflow-hidden rounded-full min-w-20 font-bold text-slate-700 text-lg"
                              color="foreground">
                            Art
                        </Link>
                        <Link isBlock href="#"
                              className="justify-center overflow-hidden rounded-full= min-w-20 font-bold text-slate-700 text-lg"
                              color="foreground">
                            Spaces
                        </Link>
                        <div className="flex items-center justify-center h-full w-full my-4 gap-2">
                            <Localization/>
                            <Button onPress={onOpen} isIconOnly radius="full"
                                    className="bg-white font-bold shadow hover:border-2 border-black">
                                <FontAwesomeIcon icon={faQuestion}/>
                            </Button>
                            <Button onPress={onOpen} isIconOnly radius="full"
                                    className="bg-white font-bold shadow hover:border-2 border-black">
                                <FontAwesomeIcon icon={faDiscord}/>
                            </Button>
                            <Button onPress={onOpen} isIconOnly radius="full"
                                    className="bg-white font-bold shadow hover:border-2 border-black">
                                <FontAwesomeIcon icon={faXTwitter}/>
                            </Button>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}

export function SpaceHeader() {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    return(
        <div>
            <div className="w-full z-20 absolute">
                <div className="flex justify-between items-center m-4">
                    <div>
                        <Link href="/">
                            <Image className="h-10 mr-4" src="/logow192.png"/>
                        </Link>
                    </div>
                    <div className="flex gap-2">
                        <Button
                            isIconOnly
                            radius="full"
                            className="text-white hover:text-black backdrop-blur bg-opacity-30 font-bold shadow hover:bg-white hover:bg-opacity-100"
                        >
                            <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </Button>
                        <Button
                            as={Link}
                            href="/login"
                            radius="full"
                            className="backdrop-blur bg-white font-bold shadow text-black hover:bottom-[1px]"
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </div>

            <Modal
                backdrop="blur"
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                hideCloseButton
                className="bg-transparent min-h-96">
                <ModalContent className="bg-white rounded-lg overflow-hidden no-scrollbar">
                    <ModalBody>
                        <div className="flex gap-2 mt-4 mb-1">
                            <Input
                                radius="full"
                                placeholder="Search your art"
                                labelPlacement="outside"
                                startContent={
                                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                }
                            />
                        </div>
                        <Link isBlock href="#"
                              className="justify-center overflow-hidden rounded-full min-w-20 font-bold text-slate-700 text-lg"
                              color="foreground">
                            Art
                        </Link>
                        <Link isBlock href="#"
                              className="justify-center overflow-hidden rounded-full= min-w-20 font-bold text-slate-700 text-lg"
                              color="foreground">
                            Spaces
                        </Link>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}