import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Divider,
    Image, Input,
    Link,
    Modal, ModalBody,
    ModalContent,
    useDisclosure
} from "@nextui-org/react";
import {
    faBars,
    faChevronDown,
    faEllipsis,
    faMagnifyingGlass,
    faMessage,
    faPlus,
    faSackDollar,
    faThumbtack, faUser,
} from "@fortawesome/free-solid-svg-icons";
import formatDate from "../../utils/formatDate"
import {space} from "../../utils/defaultData";
import {Localization} from "../Public/Header";

function Home({data}) {
    const { isOpen: isOpenModal1, onOpen: onOpenModal1, onOpenChange: onOpenChangeModal1 } = useDisclosure();
    const { isOpen: isOpenModal2, onOpen: onOpenModal2, onOpenChange: onOpenChangeModal2 } = useDisclosure();
    let pin = "";
    if (data.feature && data.feature.type !== "none") {
        if (data.feature.type === "work") {
            pin = (
                <Card className="pt-4 pb-8 mt-4 mb-16">
                    <CardHeader className="pb-4 pt-2 mx-4 w-auto">
                        <FontAwesomeIcon icon={faThumbtack}/><span className="pl-2 font-bold">Pined</span>
                    </CardHeader>
                    <CardBody className="grid grid-cols-1 md:grid-cols-2 mx-4 w-auto">
                        <div className="m-auto">
                            <Image
                                alt="Card background"
                                className="object-cover rounded-xl"
                                src={data.feature.featurePic}
                                width={300}
                            />
                        </div>
                        <div className="px-4 mt-8 md:mt-0">
                            <p className="text-3xl font-medium">{data.feature.title}</p>
                            <p className="font-semibold text-foreground/80 mb-4">By
                                {data.feature.artist.map((item, index) => (
                                    <Link color="foreground" className="ml-1">{item}</Link>
                                ))}
                            </p>
                            <p className="text-foreground/50 mb-4">"{data.feature.description}"</p>
                            <p className="text-foreground/30 mb-4">{formatDate(data.feature.date)}</p>
                        </div>
                    </CardBody>
                </Card>
            )}}

    return (
        <div className="m-auto">
            <div>
                <div className="absolute w-full z-20">
                    <div className="relative w-full">
                        <div className="flex justify-between items-center m-4">
                            <div className="flex-shrink-0">
                                <Link href="/">
                                    <Image className="h-10 mr-4" src="/logow192.png"/>
                                </Link>
                            </div>
                            <div className="absolute left-1/2 transform -translate-x-1/2 z-10">
                                <div className="hidden lg:flex gap-2 items-center mx-4 justify-center">
                                    {space.functions.map((item, index) => (
                                        item !== 'home' && (
                                            <Link
                                                key={index}
                                                isBlock
                                                href={"/space/" + space.spaceId + "/" + item.toLowerCase()}
                                                className="justify-center text-white overflow-hidden rounded-full min-w-20 text-sm font-bold px-2"
                                                color="foreground"
                                            >
                                                {item}
                                            </Link>
                                        )
                                    ))}
                                </div>
                            </div>
                            <div className="flex gap-2 flex-shrink-0">
                                <Button radius="full" isIconOnly
                                        className="backdrop-blur bg-opacity-30 font-bold shadow hover:bg-white text-white hover:text-black hover:bg-opacity-100 hover:bottom-[1px]">
                                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                </Button>
                                <Button
                                    as={Link}
                                    href="/login"
                                    radius="full"
                                    className="hidden lg:flex backdrop-blur bg-white font-bold shadow text-black hover:bottom-[1px]"
                                >
                                    Login
                                </Button>
                                <Button radius="full" isIconOnly as={Link} href="/login"
                                        className="lg:hidden backdrop-blur bg-opacity-30 font-bold shadow hover:bg-white text-white hover:text-black hover:bg-opacity-100 hover:bottom-[1px]">
                                    <FontAwesomeIcon icon={faUser}/>
                                </Button>
                                <Button radius="full" isIconOnly onClick={onOpenChangeModal1}
                                        className="lg:hidden backdrop-blur bg-opacity-30 font-bold shadow hover:bg-white text-white hover:text-black hover:bg-opacity-100 hover:bottom-[1px]">
                                    <FontAwesomeIcon icon={faBars}/>
                                </Button>
                            </div>
                            <Modal
                                backdrop="blur"
                                isOpen={isOpenModal1}
                                onOpenChange={onOpenChangeModal1}
                                hideCloseButton
                                className="bg-transparent min-h-96">
                                <ModalContent className="bg-white rounded-lg overflow-hidden no-scrollbar">
                                    <ModalBody>
                                        <div className="pt-4">
                                            <div className="text-center pt-1">
                                                <p className="text-black font-bold mb-0 text-5xl mt-1">{data.name}</p>
                                                <p className="text-black/60 font-medium text-large">{data.location}</p>
                                            </div>
                                        </div>
                                        <Divider className="my-4"/>
                                        {space.functions.map((item, index) => (
                                            item !== 'home' && (
                                                <Link
                                                    key={index}
                                                    isBlock
                                                    href={"/space/" + space.spaceId + "/" + item.toLowerCase()}
                                                    className="justify-center overflow-hidden rounded-full= min-w-20 font-bold text-slate-700 text-lg"
                                                    color="foreground"
                                                >
                                                    {item}
                                                </Link>
                                            )
                                        ))}
                                        <div className="mb-4"/>
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative w-full h-screen">
                <div className="absolute top-0 -z-10 w-full h-full">
                    <Image
                        draggable="false"
                        removeWrapper
                        radius="none"
                        className="object-cover w-full h-full brightness-50"
                        src={data.backgroundPic}
                    />
                </div>
                <div className="relative z-10 flex flex-col justify-center items-center h-full">
                    <div className="flex justify-center items-center">
                        <Image
                            draggable="false"
                            removeWrapper
                            className="object-cover h-24 aspect-square"
                            src={data.profilePic}
                        />
                    </div>
                    <div className="text-center pt-1">
                        <a href="#" onClick={onOpenModal2} className="overflow-visible text-white no-underline">
                            <p className="text-white font-bold mb-0 text-5xl md:text-7xl mt-1">{data.name}</p>
                        </a>
                        <p className="text-white/60 font-medium text-large">{data.location}</p>
                    </div>
                    <div className="flex gap-1 my-2">
                        <Button radius="full" isIconOnly
                                className="backdrop-blur bg-opacity-30 font-bold shadow hover:bg-white text-white hover:text-black hover:bg-opacity-100 hover:bottom-[1px]">
                            <FontAwesomeIcon icon={faPlus}/>
                        </Button>
                        <Button radius="full" isIconOnly
                                className="backdrop-blur bg-opacity-30 font-bold shadow hover:bg-white text-white hover:text-black hover:bg-opacity-100 hover:bottom-[1px]">
                            <FontAwesomeIcon icon={faSackDollar}/>
                        </Button>
                        <Button radius="full" isIconOnly
                                className="backdrop-blur bg-opacity-30 font-bold shadow hover:bg-white text-white hover:text-black hover:bg-opacity-100 hover:bottom-[1px]">
                            <FontAwesomeIcon icon={faMessage}/>
                        </Button>
                        <Button radius="full" isIconOnly
                                className="backdrop-blur bg-opacity-30 font-bold shadow hover:bg-white text-white hover:text-black hover:bg-opacity-100 hover:bottom-[1px]">
                            <FontAwesomeIcon icon={faEllipsis}/>
                        </Button>
                    </div>
                    <Modal isOpen={isOpenModal2} onOpenChange={onOpenChangeModal2} hideCloseButton>
                        <ModalContent>
                            {(onClose) => (
                                <div className="m-12">
                                    <div className="flex justify-center items-center">
                                        <Image
                                            draggable="false"
                                            removeWrapper
                                            className="object-cover opacity-100 h-24 aspect-square"
                                            src={data.profilePic}
                                        />
                                    </div>
                                    <div className="text-center my-4">
                                        <p className="font-bold mb-0 text-5xl mt-1">{data.name}</p>
                                        <p className="font-medium text-large">{data.location}</p>
                                    </div>
                                    <Divider className="my-8"/>
                                    <div className="text-center">
                                        {data.social.map((item, index) => (
                                            <p key={index}><Link color="foreground" className="text-large mr-2"
                                                                 href={item.link}>{item.platform}</Link></p>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </ModalContent>
                    </Modal>
                    <div
                        className="absolute bottom-0 bg-gradient-to-t from-white h-52 w-full flex justify-center items-end">
                        <a href="#main">
                            <FontAwesomeIcon icon={faChevronDown} className="text-2xl mb-10"/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="min-h-[500px] max-w-[1024px] mx-auto px-4" id="main">
                <div className="pt-4">
                {pin}
                <div className="grid grid-cols-2 my-4">
                <p className="font-bold text-2xl mb-0">Featured works</p>
                </div>
                <div className="gap-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
                    {data.work && data.work.map((item, index) => (
                        <div>
                            <a href={"./work/" + item.workId}>
                                <Card key={index}>
                                    <CardBody className="p-0">
                                        <Image
                                            draggable="false"
                                            alt={item.title}
                                            className="object-cover"
                                            src={item.coverImg}
                                        />
                                    </CardBody>
                                </Card>
                                <div className="mt-3">
                                    <p className="font-bold mb-0 ">{item.title}</p>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
                </div>
            </div>
        </div>
    );
}

export default Home
