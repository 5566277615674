import {Link, ScrollShadow} from "@nextui-org/react";
import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {space} from "../../../utils/defaultData";

export function Menu() {
    const { pathname } = useLocation();
    const regex = /^\/space\/(\w+)\/?(\w+)?\/?(\w+)?\/?$/;
    const base = pathname.match(regex);
    if (base) {
        const spaceId = base[1]
        let nav = "home";
        if (base[2]) {
            nav = base[2]
        }

        return (
            <div className="">
                <ScrollShadow orientation="horizontal">
                    <div className="mb-3">
                        <div className="flex gap-2 mt-3 items-center mx-4 justify-start md:justify-center">
                            {space.functions.map((item, index) => (
                                <Link isBlock href={"/space/"+spaceId+(item === 'home' ? "/" : "/"+item.toLowerCase() )}
                                      className={"justify-center overflow-hidden rounded-full min-w-20 text-sm font-bold text-black px-2 " + ((item.toLowerCase() === nav) ? "bg-black bg-opacity-15" : "text-opacity-50 ")}
                                      color="foreground">
                                    {item}
                                </Link>
                            ))}
                        </div>
                    </div>
                </ScrollShadow>
            </div>
        )
    }
    else {
        return (
            <Navigate to="/404" />
        )
    }

}