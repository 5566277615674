import React, {useEffect} from "react";
import {Header} from "../../Public/Header";
import {Footer} from "../../Public/Footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Image} from "@nextui-org/react";
import {
    faBlog,
    faCalendarDay, faCode,
    faComments, faCopyright, faEllipsis, faFile, faFileContract, faHandshake,
    faImages,
    faShop,
    faBullhorn,
} from "@fortawesome/free-solid-svg-icons";
import {discord_link} from "../../../globalVariable";
import {useTranslation} from "react-i18next";

function Solution() {
    const { t } = useTranslation();

  return (
      <div>
      <Header/>
          <div className="container mx-auto px-4">
              <div style={{margin: "30px 0"}}>
                  <div style={{backgrounddivor: "rgba(0,0,0,0.05)", borderRadius: "30px", padding: "50px 30px 0 30px"}}>
                      <div>
                          <p style={{fontWeight: "bold", fontSize: "30px", lineHeight: "1em"}}>{t("support_1_1")}</p>
                          <p style={{marginBottom: 0}}>{t("support_1_2")}</p>
                          <div style={{textAlign: "center", marginTop: "30px", marginBottom: "30px"}}>
                              <div>
                                  <div className="mb-4 mb-lg-0" xs={12} md={4}>
                                      <div style={{padding: "30px 30px"}}>
                                          <p style={{
                                              fontSize: "20px",
                                              marginBottom: "30px",
                                              fontWeight: "bold"
                                          }}>{t("support_1_3")}</p>
                                          <div>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faCalendarDay}/>
                                                  <p>{t("support_1_4")}</p>
                                              <div/>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faImages}/>
                                                  <p>{t("support_1_5")}</p>
                                              </div>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faComments}/>
                                                  <p>{t("support_1_6")}</p>
                                              </div>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faBlog}/>
                                                  <p>{t("support_1_7")}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="mb-4 mb-lg-0">
                                      <div style={{padding: "30px 30px"}}>
                                          <p style={{
                                              fontSize: "20px",
                                              marginBottom: "30px",
                                              fontWeight: "bold"
                                          }}>{t("support_1_8")}</p>
                                          <div>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faShop}/>
                                                  <p>{t("support_1_9")}</p>
                                              </div>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faHandshake}/>
                                                  <p>{t("support_1_10")}</p>
                                              </div>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faFile}/>
                                                  <p>{t("support_1_11")}</p>
                                              </div>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faCopyright}/>
                                                  <p>{t("support_1_12")}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="mb-4 mb-lg-0" xs={12} md={4}>
                                      <div style={{padding: "30px 30px"}}>
                                          <p style={{
                                              fontSize: "20px",
                                              marginBottom: "30px",
                                              fontWeight: "bold"
                                          }}>{t("support_1_13")}</p>
                                          <div>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faFileContract}/>
                                                  <p>{t("support_1_14")}</p>
                                              </div>
                                              <div xs={6}>
                                                  <p style={{
                                                      fontSize: "20px",
                                                      marginBottom: 0,
                                                      fontWeight: "bolder"
                                                  }}>NFT</p>
                                                  <p>{t("support_1_15")}</p>
                                              </div>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faCode}/>
                                                  <p>{t("support_1_16")}</p>
                                              </div>
                                              <div xs={6}>
                                                  <FontAwesomeIcon style={{fontSize: '30px'}} icon={faEllipsis}/>
                                                  <p>{t("support_1_17")}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div>
                  <div md={6}><p style={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      marginBottom: "10px",
                      paddingLeft: "30px"
                  }}>{t("support_2_1")}</p></div>
                  <div md={6} className="d-none d-md-block"><p style={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      marginBottom: "10px",
                      paddingLeft: "30px"
                  }}>{t("support_2_2")}</p></div>
                  <div md={6} style={{minHeight: "300px", marginBottom: "30px"}}>
                      <div style={{height: "50%"}}>
                          <div style={{marginBottom: "10px"}}>
                              <div style={{backgrounddivor: "rgba(0,0,0,0.05)", borderRadius: "30px", height: "100%"}}>
                                  <p style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      marginBottom: 0,
                                      padding: "30px 0 0 30px"
                                  }}>{t("support_2_3")}</p>
                              </div>
                          </div>
                      </div>
                      <div style={{height: "50%"}}>
                          <div style={{marginTop: "10px"}}>
                              <div style={{backgrounddivor: "rgba(0,0,0,0.05)", borderRadius: "30px", height: "100%"}}>
                                  <p style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      marginBottom: 0,
                                      padding: "30px 0 0 30px"
                                  }}>{t("support_2_4")}</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div md={6} className="d-block d-md-none"><p style={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      marginBottom: "10px",
                      paddingLeft: "30px"
                  }}>{t("support_2_2")}</p></div>
                  <div md={6} style={{marginBottom: "30px"}}>
                      <div>
                          <div style={{backgrounddivor: "rgba(0,0,0,0.05)", borderRadius: "30px", minHeight: "300px"}}>
                              <div style={{padding: "30px 30px 0 30px"}}>
                                  <FontAwesomeIcon style={{
                                      fontSize: '20px',
                                      marginRight: "10px",
                                      float: "left",
                                      position: "relative",
                                      top: "5px"
                                  }} icon={faBullhorn}/>
                                  <div style={{marginLeft: "30px"}}><p
                                      style={{marginBottom: "0"}}>{t("broadcast_2")}</p></div>
                              </div>
                              <div style={{padding: "10px 30px 0 30px"}}>
                                  <FontAwesomeIcon style={{
                                      fontSize: '20px',
                                      marginRight: "10px",
                                      float: "left",
                                      position: "relative",
                                      top: "5px"
                                  }} icon={faBullhorn}/>
                                  <div style={{marginLeft: "30px"}}><p
                                      style={{marginBottom: "0"}}>{t("broadcast_3")}</p></div>
                              </div>
                              <div style={{padding: "10px 30px 30px 30px"}}>
                                  <FontAwesomeIcon style={{
                                      fontSize: '20px',
                                      marginRight: "10px",
                                      float: "left",
                                      position: "relative",
                                      top: "5px"
                                  }} icon={faBullhorn}/>
                                  <div style={{marginLeft: "30px"}}><p
                                      style={{marginBottom: "0"}}>{t("broadcast_4")}</p></div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div>
                  <div>
                      <p style={{fontWeight: "bold", fontSize: "30px", paddingLeft: "30px"}}>{t("support_3_1")}</p>
                      <div style={{backgrounddivor: "rgba(0,0,0,0.05)", borderRadius: "30px"}}>
                          <div style={{padding: "30px"}}>
                              <div md={4} className="d-none d-md-block"><Image src="./informational/demo/lilnerdy.png"
                                                                               rounded fluid/></div>
                              <div className="d-block d-md-none"><Image src="./informational/demo/lilnerdy_sq.png"
                                                                        rounded fluid/></div>
                              <div md={8} style={{margin: "30px 0 0 0"}}>
                                  <p style={{marginBottom: 0, fontWeight: "bolder", fontSize: "25px"}}>Lil Nerdy</p>
                                  <p style={{
                                      fontWeight: "bolder",
                                      divor: "grey",
                                      marginBottom: "50px",
                                      fontSize: "20px",
                                      lineHeight: "1.1em"
                                  }}>{t("support_3_2")}</p>
                                  <div style={{height: "100px"}}>
                                      <div style={{width: "130px", float: "left"}}>
                                          <p style={{fontWeight: "bolder", marginBottom: 0}}>{t("support_3_3")}</p>
                                          <p style={{fontWeight: "bolder", marginBottom: "30px"}}>{t("support_3_5")}</p>
                                      </div>
                                      <div style={{width: "150px", float: "left"}}>
                                          <p style={{fontWeight: "bolder", marginBottom: 0}}>{t("support_3_4")}</p>
                                          <p style={{fontWeight: "bolder", marginBottom: "50px"}}>{t("support_3_6")}</p>
                                      </div>
                                  </div>
                                  <p style={{marginBottom: "20px"}}>{t("support_3_7")}</p>
                                  <a href="/space/demo/"
                                     style={{textDecoration: "none", fontWeight: "bold", divor: "#0047FF"}}><p
                                      style={{textAlign: "right", margin: "0 30px"}}>{t("support_3_8")}</p></a>
                              </div>
                          </div></div>
                  </div>

              </div>
              <div style={{marginTop: '30px'}}>
                  <div>
                      <p style={{
                          fontWeight: "bold",
                          fontSize: "30px",
                          marginBottom: "10px",
                          paddingLeft: "30px"
                      }}>{t("support_4_1")}</p>
                      <div style={{
                          backgrounddivor: "rgba(0,0,0,0.05)",
                          borderRadius: "30px",
                          padding: "50px 30px 0 30px"
                      }}>
                          <div style={{padding: "0 0 30px 0"}}>
                              <div style={{paddingBottom: "50px"}}>
                                  <p style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      marginBottom: "0"
                                  }}>{t("support_4_2")}</p>
                                  <p style={{divor: "grey"}}>{t("support_4_3")}</p>
                                  <p>{t("support_4_4")}</p>
                              </div>
                              <div style={{paddingBottom: "50px"}}>
                                  <p style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      marginBottom: "0"
                                  }}>{t("support_4_5")}</p>
                                  <p style={{divor: "grey"}}>{t("support_4_6")}</p>
                                  <p>{t("support_4_7")}</p>
                              </div>
                              <div>
                                  <p style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      marginBottom: "0"
                                  }}>{t("support_4_8")}</p>
                                  <p style={{divor: "grey"}}>{t("support_4_9")}</p>
                                  <p>{t("support_4_10")}</p>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
              <div style={{marginTop: '30px'}}>
                  <div>
                      <p style={{
                          fontWeight: "bold",
                          fontSize: "30px",
                          marginBottom: "10px",
                          paddingLeft: "30px"
                      }}>{t("support_5_1")}</p>
                      <div style={{
                          backgrounddivor: "rgba(0,0,0,0.05)",
                          borderRadius: "30px",
                          padding: "50px 30px 0 30px"
                      }}>
                          <div style={{padding: "0 0 30px 0"}}>
                              <div style={{paddingBottom: "50px"}}>
                                  <p style={{fontSize: "20px", fontWeight: "bold"}}>{t("support_5_2")}</p>
                                  <p>{t("support_5_3")}</p>
                                  <a href={discord_link} style={{
                                      textDecoration: "none",
                                      marginBottom: "50px",
                                      divor: "#0047FF"
                                  }}>{t("support_5_4")}</a>
                              </div>
                              <div>
                                  <p style={{fontSize: "20px", fontWeight: "bold"}}>{t("support_5_5")}</p>
                                  <p>{t("support_5_6")}</p>
                                  <p>{t("support_5_7")}</p>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
              <div style={{marginTop: '30px', marginBottom: "30px"}}>
                  <div>
                      <p style={{
                          fontWeight: "bold",
                          fontSize: "30px",
                          marginBottom: "10px",
                          paddingLeft: "30px"
                      }}>{t("support_6_1")}</p>
                      <div style={{
                          backgrounddivor: "rgba(0,0,0,0.05)",
                          borderRadius: "30px",
                          padding: "50px 30px 0 30px"
                      }}>
                          <div style={{padding: "0 0 30px 0"}}>
                              <div style={{padding: "10px 0 10px 0"}}>
                                  <p style={{fontSize: "20px", fontWeight: "bold"}}>{t("support_6_2")}</p>
                                  <p>{t("support_6_3")}</p>
                              </div>
                              <div style={{padding: "10px 0 10px 0"}}>
                                  <p style={{fontSize: "20px", fontWeight: "bold"}}>{t("support_6_4")}</p>
                                  <p>{t("support_6_5")}</p>
                              </div>
                              <div style={{padding: "10px 0 10px 0"}}>
                                  <p style={{fontSize: "20px", fontWeight: "bold"}}>{t("support_6_6")}</p>
                                  <p>{t("support_6_7")}</p>
                                  <p>{t("support_6_8")}</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <Footer/>
      </div>
      </div>
  );
}

export default Solution;
