import axios from 'axios';
import {api_baseUrl} from "../globalVariable";

const axiosInstance = axios.create({
    baseURL: api_baseUrl,
    timeout: 10000 // Optionally set other default options
});

// Add a request interceptor to inject the token before each request
axiosInstance.interceptors.request.use(
    config => {
        // Retrieve token from somewhere your app stores it, e.g., localStorage
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosInstance;