import React, { useState, useEffect  } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {Image} from "@nextui-org/react";

export default function Language() {
    const navigate = useNavigate();
    const { t,i18n } = useTranslation();
  return (
        <div>
            <div style={{ textAlign: "center", marginTop: "20vh"}}>
                <a onClick={() => navigate(-1)} style={{position: "absolute", top: "30px", left: "30px"}}><FontAwesomeIcon style={{fontSize: '30px', marginRight: "10px"}} icon={faArrowLeft} /></a>
                <div style={{marginBottom: "30px"}}>
                    <a href="/src/Components/Public"><Image src="/logo512.png" rounded fluid style={{height: "100px"}}/></a>
                </div>
                <a href="/src/Components/Public" onClick={() => i18n.changeLanguage("en_us")} style={{ color: "black", display: "block", fontSize: "20px", textDecoration: "none"}}>English</a>
                <a href="/src/Components/Public" onClick={() => i18n.changeLanguage("zh_cn")} style={{ color: "black", display: "block", fontSize: "20px", textDecoration: "none"}}>简体中文</a>
                <a style={{ color: "grey", display: "block", fontSize: "20px", textDecoration: "none"}}>日本語</a>
                <a style={{ color: "grey", display: "block", fontSize: "20px", textDecoration: "none"}}>한국어</a>
            </div>
        </div>
  );
}

export function LanguageModal() {
    const navigate = useNavigate();
    const { t,i18n } = useTranslation();
    return (
        <div style={{textAlign: "center", marginBottom: "50px"}}>
            <a onClick={() => i18n.changeLanguage("en_us")} style={{
                color: "black",
                display: "block",
                fontSize: "20px",
                textDecoration: "none"
            }}>English</a>
            <a onClick={() => i18n.changeLanguage("zh_cn")} style={{
                color: "black",
                display: "block",
                fontSize: "20px",
                textDecoration: "none"
            }}>简体中文</a>
            <a style={{
                color: "grey",
                display: "block",
                fontSize: "20px",
                textDecoration: "none"
            }}>日本語</a>
            <a style={{
                color: "grey",
                display: "block",
                fontSize: "20px",
                textDecoration: "none"
            }}>한국어</a>
        </div>
    );
}
