import React from 'react';
import { Header } from '../Public/Header';
import { Footer } from '../Public/Footer';
import {
    faScrewdriverWrench,
    faLink,
    faBinoculars,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {Button, Card, CardBody, Image, Link} from '@nextui-org/react';

function Home() {
    const { t } = useTranslation();

    return (
        <div>
            <Header/>
            <div className="max-w-[1280px] mx-auto px-10">
                <div className="my-24">
                    <Image src="./informational/hello_world.png" className="rounded" />
                </div>
                <div id="detail1" className="m-12">
                    <div className="text-center">
                        <div>
                            <p className="md:hidden block font-bold text-3xl leading-none mb-5">{t("intro_1_1")}</p>
                            <p className="hidden md:block font-bold text-5xl leading-none mb-3">{t("intro_1_1")}</p>
                        </div>
                        <div>
                            <p>{t("intro_1_2")}</p>
                        </div>
                        <div className="gap-4 grid grid-cols-1 md:grid-cols-3 my-4">
                            <div className="mb-4 md:mb-0 p-8">
                                <FontAwesomeIcon className="text-5xl my-12" icon={faScrewdriverWrench}/>
                                <p>{t("intro_1_3")}</p>
                            </div>
                            <div className="mb-4 md:mb-0 p-8">
                                <FontAwesomeIcon className="text-5xl my-12" icon={faLink}/>
                                <p>{t("intro_1_4")}</p>
                            </div>
                            <div className="mb-4 md:mb-0 p-8">
                                <FontAwesomeIcon className="text-5xl my-12" icon={faBinoculars}/>
                                <p>{t("intro_1_5")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Card className="pt-4 pb-8 my-24">
                    <CardBody className="grid grid-cols-1 md:grid-cols-2 mx-4 w-auto">
                        <div className="m-auto">
                            <Image src="./informational/web.png" className="p-12" />
                        </div>
                        <div className="px-4 mt-8 md:mt-0">
                            <p className="font-bold text-gray-500 mb-2 text-lg leading-snug"><FontAwesomeIcon
                                className="text-lg mx-2" icon={faScrewdriverWrench}/>BUILD</p>
                            <p className="font-bold text-3xl leading-snug mb-8">{t("intro_2_1")}</p>
                            <p>{t("intro_2_2")}</p>
                        </div>
                    </CardBody>
                </Card>
                <Card className="pt-4 pb-8 my-24">
                    <CardBody className="grid grid-cols-1 md:grid-cols-2 mx-4 w-auto">
                        <div className="px-4 mt-8 md:mt-0">
                            <p className="font-bold text-gray-500 mb-2 text-lg leading-snug"><FontAwesomeIcon
                                className="text-lg mx-2" icon={faLink}/>CONNECT</p>
                            <p className="font-bold text-3xl leading-snug mb-8">{t("intro_3_1")}</p>
                            <p>{t("intro_3_2")}</p>
                        </div>
                        <div className="m-auto">
                            <Image src="./informational/publicpool.png" className="p-12"/>
                        </div>

                    </CardBody>
                </Card>
                <Card className="pt-4 pb-8 my-24">
                    <CardBody className="grid grid-cols-1 md:grid-cols-2 mx-4 w-auto">
                        <div className="m-auto">
                            <Image src="./informational/artstyle.png" className="rounded"/>
                        </div>
                        <div className="px-4 mt-8 md:mt-0">
                            <p className="font-bold text-gray-500 mb-2 text-lg leading-snug"><FontAwesomeIcon
                                className="text-lg mx-2" icon={faBinoculars}/>DISCOVER</p>
                            <p className="font-bold text-3xl leading-snug mb-8">{t("intro_4_1")}</p>
                            <p>{t("intro_4_2")}</p>
                        </div>
                    </CardBody>
                </Card>
                <div id="detail5" className="my-52">
                    <div className="text-center">
                        <p className="md:hidden block font-bold text-3xl leading-snug mb-5">{t("intro_5_1")}</p>
                        <p className="hidden md:block font-bold text-5xl leading-snug mb-3">{t("intro_5_1")}</p>
                        <div>
                            <p>{t("intro_5_2")}</p>
                        </div>
                        <div className="mt-8">
                            <Button as={Link} href="/login" radius="full"
                                    className="font-bold shadow bg-black text-white">
                                {t("get_started")}
                            </Button>
                            <Link color="foreground" className="mt-2 block font-bold">{t("learn_more")}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
export default Home;

