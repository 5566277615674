import {Routes, Route, Link, Navigate} from "react-router-dom";

import React, { Component } from 'react';

import Home from '../Main/Home'
import {Header} from "../Public/Header";
import {Footer} from "../Public/Footer";


function Main() {
    return (
        <div>
            <Header/>
            <div className="max-w-[1280px] mx-auto px-4">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="*" element={<Navigate to="/404"/>}/>
                </Routes>
            </div>
            <Footer/>
        </div>
    );
}

export default Main;

