import {t} from "i18next";
import React from "react";
import {Divider, Link} from "@nextui-org/react";
import {Image} from "@nextui-org/react";

export function Footer() {
    return (
        <div>
            <Divider className="mb-4 text-black mt-16"/>
            <div className="mx-4 mb-4 grid md:grid-cols-2 sm:grid-cols-1">
                <div className="flex gap-4">
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        Twitter
                    </Link>
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        Discord
                    </Link>
                </div>
                <div className="flex md:flex-row-reverse gap-4">
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        About
                    </Link>
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        Help
                    </Link>
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        ToS
                    </Link>
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        Privacy
                    </Link>
                </div>
            </div>
        </div>
    );
}

export function SpaceFooter(){
    return(
        <div>
            <Divider className="mb-4 text-black mt-16"/>
            <div className="mx-4 mb-4 grid md:grid-cols-2 sm:grid-cols-1">
                <div className="flex gap-4">
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        Twitter
                    </Link>
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        Discord
                    </Link>
                </div>
                <div className="flex md:flex-row-reverse gap-4">
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        About
                    </Link>
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        Help
                    </Link>
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        ToS
                    </Link>
                    <Link href="#" className="font-bold opacity-30 text-sm" color="foreground">
                        Privacy
                    </Link>
                </div>
            </div>
        </div>
    )
}