import { Routes, Route, Link, Navigate } from "react-router-dom";

import React, { Component } from "react";
import "./App.css";

import Informational from "./Informational";
import Space from "./Space";

import {PageNotFound} from "./Public/PageNotFound"
import {PageInDevelopment} from "./Public/PageInDevelopment"

import {NextUIProvider} from "@nextui-org/react";
import Main from "./Main";
import About from "./Informational/About";
import Support from "./Informational/Support";
import Blog from "./Informational/Blog";
import Post from "./Informational/Blog/Post";
import Language from "./Public/Language";
import Intro from "./Informational/Intro";
import {Login} from "./Public/Login";

function App() {

  return (
      <NextUIProvider>
        <div>
            <main>
              <Routes>
                <Route path="/*" element={<Main />} />
                <Route path="/space/:spaceId/*" element={<Space />} />
                <Route path="/404" element={<PageNotFound />} />
                <Route path="/dev" element={<PageInDevelopment />} />
                <Route path="/intro" element={<Intro/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/support" element={<Support/>}/>
                <Route path="/blog" element={<Blog/>}/>
                <Route path="/post/:postId" element={<Post/>}/>
                <Route path="/language" element={<Language/>}/>
                <Route path="*" element={<Navigate to="/404" />} />
              </Routes>
            </main>
          </div>
      </NextUIProvider>
  );
}

export default App;

