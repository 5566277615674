export const space =
    {
        "data": {
            "follow": 0,
            "visit": 0
        },
        "about": {
            "description": "XIP is a multifaceted concept: a symbol of inclusivity, a space for intellectual and creative engagement, a challenge to traditional notions of importance, and a portal to new dimensions of thought and creativity. It's a concept that is as diverse and dynamic as the community it seeks to embrace. It's a journey that intertwines art, spirituality, psychology, and philosophy. XIP is a platform for exploration, understanding, and connection, transcending traditional boundaries of art and inviting a deeper engagement with the mysteries of the inner and outer cosmos.",
            "team": [
                {
                    "name": "Steamedbun",
                    "userId": 1,
                    "affiliation": "Labelindie",
                    "credit": [
                        "Drum",
                        "Cover Arts"
                    ]
                }
            ],
            "recordLabels": [
                "Aura Music Corp."
            ],
            "Genre": [
                "Alternative/Indie",
                "Dance/Electronic",
                "Folk"
            ]
        },
        "_id": "66298a3c4721cc1972576891",
        "spaceId": "demo",
        "name": "LABELINDIE",
        "location": "Los Angeles, USA",
        "social": [
            {
                "platform": "Website",
                "link": "labelindie.com"
            },
            {
                "platform": "Twitter",
                "link": ""
            },
            {
                "platform": "Youtube",
                "link": ""
            },
            {
                "platform": "Instagram",
                "link": ""
            }
        ],
        "functions": [
            "home",
            "work",
            "story",
            "event",
            "shop",
            "blog",
            "nft",
            "about"
        ],
        "work": [
            {
                "workId": 1,
                "title": "Demo",
                "type": "audio"
            }
        ],
        "createdAt": "2024-04-24T22:39:56.329Z",
        "__v": 0,
        "feature": {
            "type": "work",
            "workId": "1",
            "title": "Demo",
            "artist": [
                "Labelindie"
            ],
            "description": "I'm always afraid when what I have now will disappear and when people will leave me. I think these are some kind of magic, that will shine bright for a while and then lights out, like nothing happened. This is an album that I made with my dreams I dreamed after my 2nd album. Thanks to people all over the world for the help.",
            "date": "2024-05-20T09:00:00.000Z"
        },
        "profilePic": "https://s3.us-west-1.amazonaws.com/labelindie.test/space/demo/profilePic.jpg?AWSAccessKeyId=AKIAVJJKA2UZS273YZ5A&Expires=1714548256&Signature=2MYHG0SmyOgsnm4O64Okv8lOyhU%3D",
        "backgroundPic": "https://s3.us-west-1.amazonaws.com/labelindie.test/space/demo/backgroundPic.jpg?AWSAccessKeyId=AKIAVJJKA2UZS273YZ5A&Expires=1714548256&Signature=aCZIeX56cbbaR9voCthfl%2F6mTng%3D"
    }

export const work =
    {
        "data": {
            "visit": 0,
            "like": 0,
            "save": 0
        },
        "detail": {
            "type": "album",
            "songNumber": 2
        },
        "workId": 1,
        "title": "Demo Work",
        "artists": [
            {
                "name": "Steamedbun",
                "userId": 1,
                "affiliation": "Labelindie",
                "credit": [
                    "Compose",
                ]
            }
        ],
        "creationDate": "2024-04-24T22:39:56.329Z",
        "description": "This work is for the demo display.",
        "tags": [
            "Jazz",
        ],
        "content": [
            {
                "data": {
                    "visit": 0,
                    "like": 0,
                    "save": 0
                },
                "credits": {
                    "description": "120BPM with a swing beat.",
                    "content": [
                        {
                            "type": "Artist",
                            "name": "Steamedbun",
                            "userId": 1,
                            "affiliation": "Labelindie",
                            "credit": [
                                "Compose",
                            ]
                        }
                    ]
                },
                "songId": 1,
                "sequence": 1,
                "title": "Demo Song #1",
                "type": "mp3",
                "creationDate": "2024-04-24T22:39:56.329Z",
                "artists": [
                    {
                        "name": "Steamedbun",
                        "userId": 1,
                        "affiliation": "Labelindie",
                        "credit": [
                            "Compose",
                        ]
                    }
                ],
                "duration": "00:17",
                "description": "Demo 1 is a jazz intro",
                "tags": [
                    "Jazz",
                ],
                "release": [
                    "Labelindie Music."
                ],
                "lyric": "No Lyrics",
                "cLine": "2023 Labelindie Music.",
                "pLine": "2023 Labelindie Music.",
            },
            {
                "data": {
                    "visit": 0,
                    "like": 0,
                    "save": 0
                },
                "credits": {
                    "description": "120BPM with a swing beat.",
                    "content": [
                        {
                            "type": "Artist",
                            "name": "Steamedbun",
                            "userId": 1,
                            "affiliation": "Labelindie",
                            "credit": [
                                "Compose",
                            ]
                        }
                    ]
                },
                "songId": 2,
                "sequence": 2,
                "title": "Demo Song #2",
                "type": "mp3",
                "creationDate": "2024-04-24T22:39:56.329Z",
                "artists": [
                    {
                        "name": "Steamedbun",
                        "userId": 1,
                        "affiliation": "Labelindie",
                        "credit": [
                            "Compose",
                        ]
                    }
                ],
                "duration": "00:17",
                "description": "Demo 2 is a jazz verse",
                "tags": [
                    "Jazz",
                ],
                "release": [
                    "Labelindie Music."
                ],
                "lyric": "No Lyrics",
                "cLine": "2023 Labelindie Music.",
                "pLine": "2023 Labelindie Music.",
            }
        ],
    }