import "./Post.css";
import {Image} from "@nextui-org/react";
import {useEffect} from "react";
import {Header} from "../../Public/Header";
import {Footer} from "../../Public/Footer";
import {Route, Link, useParams, Navigate} from 'react-router-dom';
import {admin_secret, admin_username, api_baseUrl} from "../../../globalVariable";
import axios from "axios";
import {useState} from "react";

function Post(){
    const params = useParams();

    const [post, setPost] = useState([
        {
            title: "Loading",
        }
    ])

    useEffect(() => {
    let requestUrl = api_baseUrl + "api/token/obtain/";
    let blogUrl = api_baseUrl + "api/blog";

    //Get tokens
    axios
      .post(requestUrl, {
        username: admin_username,
        password: admin_secret,
      })
      .then(function (response) {
        console.log(response.data.access);

        //Get artwork
          console.log(params.postId)
        axios
          .get(blogUrl, {
            headers: {
              Authorization: "Bearer " + response.data.access,
            },
              params: {
                id: params.postId,
            }
          })
          .then(function (response) {
            console.log(response.data);
            setPost(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
    return(
        <div>
            <Header/>
            <div className="container mx-auto px-4">
                {post.map((value, index) => {
                    return (
                        <div>
                            <div className="text-center" style={{marginBottom: '30px'}}>
                                <h2>{value.title}</h2>
                                <p>{value.date}</p>
                                <div className="text-center">
                                    <Image src={value.image} rounded fluid/>
                                </div>
                            </div>
                            <div>
                                <p>{value.body}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <Footer/>
            {post.length === 0 && (<Navigate to="/404"/>)}
        </div>
    )
}

export default Post;

