import React, {useEffect} from "react";
import {Header} from "../Public/Header";
import {Footer} from "../Public/Footer";
import {discord_link} from "../../globalVariable";
import {useTranslation} from "react-i18next";

function About() {
    const { t } = useTranslation();
    return (
        <div>
            <Header/>
            <div className="container mx-auto px-4">
                <div style={{margin: "30px 0"}}>
                    <p style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        marginBottom: "10px",
                        paddingLeft: "30px"
                    }}>{t("about_1_1")}</p>
                    <div style={{
                        backgroundColor: "rgba(0,0,0,0.05)",
                        borderRadius: "30px",
                        padding: "50px 30px 30px 30px",
                        marginBottom: "30px"
                    }}>
                                <div style={{padding: "0 0 10px 0"}}>
                                    <div style={{paddingBottom: "50px"}}>
                                        <p style={{fontSize: "20px", fontWeight: "bold"}}>{t("about_1_2")}</p>
                                        <p>{t("about_1_3")}</p>
                                    </div>
                                    <div style={{paddingBottom: "50px"}}>
                                        <p style={{fontSize: "20px", fontWeight: "bold"}}>{t("about_1_4")}</p>
                                        <p>{t("about_1_5")}</p>
                                    </div>
                                    <div style={{paddingBottom: "50px"}}>
                                        <p style={{fontSize: "20px", fontWeight: "bold"}}>{t("about_1_6")}</p>
                                        <p>{t("about_1_7")}</p>
                                    </div>
                                </div>
                        <div style={{marginBottom: "50px"}}>
                            <div className="text-center">
                                <p style={{marginBottom: 0}}>{t("about_1_8")}</p>
                                <p style={{marginBottom: 0}}>{t("about_1_9")}</p>
                                <p style={{marginBottom: "30px"}}>{t("about_1_10")}</p>
                                <p style={{marginBottom: "10px"}}>{t("about_1_11")}</p>

                                <a href={discord_link}>
                                    <div style={{fontWeight: "bold", backgroundColor: "#0047FF"}}>
                                        {t("about_1_12")}
                                    </div>{' '}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default About;
