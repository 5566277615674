import {Routes, Route, Navigate, useLocation, useParams} from "react-router-dom";

import React, {Component, useEffect, useState} from 'react';
import {SpaceHeader, Header} from "../Public/Header";
import Home from "./Home";
import {SpaceFooter} from "../Public/Footer";
import {Background} from "./Public/Background";
import {Menu} from "./Public/Menu";
import axiosInstance from "../../utils/axiosInstance";
import Gateway from "./Gateway";

function Space() {

    const { spaceId } = useParams();
    const [data, setData] = useState([]);
    useEffect(() => {
        axiosInstance.get(`/space/${spaceId}`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div>
                <Routes>
                    <Route path="/" element={<Home data={data}/>}/>
                    <Route path="/*" element={<Gateway data={data}/>}/>
                </Routes>
            <SpaceFooter/>
        </div>
    );
}

export default Space;