import React, {useEffect, useState} from "react";
import {Card, CardBody, Image, Select, SelectItem, SelectSection} from "@nextui-org/react";

function Work({data}) {
    return (
        <div className="bg-whit">
            <div>
                <div className="grid grid-cols-2 my-4">
                    <p className="font-bold text-2xl mb-0">All works</p>
                </div>
                <div className="gap-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
                    {data.work && data.work.map((item, index) => (
                        <div>
                            <a href={"./work/" + item.workId}>
                                <Card key={index}>
                                    <CardBody className="p-0">
                                        <Image
                                            draggable="false"
                                            alt={item.title}
                                            className="object-cover"
                                            src={item.coverImg}
                                        />
                                    </CardBody>
                                </Card>
                                <div className="mt-3">
                                    <p className="font-bold mb-0 ">{item.title}</p>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Work