const initialState = {
    tracks: [
        // array of track objects
        // each track object should at least have a unique identifier
    ],
    currentTrackIndex: 0,
    isPlaying: false,
    currentTime: 0,
};

function musicReducer(state = initialState, action) {
    switch (action.type) {
        case 'LOAD_TRACK':
            return { ...state, tracks: action.payload};
        case 'SET_TRACK':
            return { ...state, currentTrack: action.payload, isPlaying: true };
        case 'PLAY_TRACK':
            return { ...state, isPlaying: true };
        case 'PAUSE_TRACK':
            return { ...state, isPlaying: false };
        case 'NEXT_TRACK':
            const nextIndex = (state.currentTrackIndex + 1) % state.tracks.length;
            return { ...state, currentTrackIndex: nextIndex, isPlaying: true };
        case 'PREV_TRACK':
            // Logic to determine the previous track
            const prevIndex = (state.currentTrackIndex - 1 + state.tracks.length) % state.tracks.length;
            return { ...state, currentTrackIndex: prevIndex, isPlaying: true };
        case 'SET_SEEK':
            return { ...state, currentTime: action.payload };
        default:
            return state;
    }
}

export default musicReducer;
