import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Image} from "@nextui-org/react";
import axiosInstance from "../../utils/axiosInstance";
import {Swiper} from "swiper/react";
import CustomSwiper from "../Public/Swiper";


function Home() {
    const [workData, setWorkData] = useState([]);
    const [spaceData, setSpaceData] = useState([]);

    useEffect(() => {
        axiosInstance.get(`/space/`)
            .then(response => {
                setSpaceData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);
    useEffect(() => {
        axiosInstance.get(`/work/`)
            .then(response => {
                setWorkData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <div>
            <div className="mt-4 mb-16">
                <CustomSwiper/>
            </div>
            <div className="mt-4 mb-16">
                <p className="font-bold text-2xl my-4">Featured works</p>
                <div className="gap-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
                    {workData.map((item, index) => (
                        <div>
                            <a href={`space/${item.space}/work/${item.workId}`}>
                                <Card key={index}>
                                    <CardBody className="p-0">
                                        <Image
                                            draggable="false"
                                            alt={item.title}
                                            className="object-cover"
                                            src={item.coverPicUrl}
                                        />
                                    </CardBody>
                                </Card>
                                <div className="mt-3">
                                    <p className="font-bold mb-0 ">{item.title}</p>
                                    {item.artists.map((item, index) => (
                                        <p className="text-default-500">{item.name}</p>
                                    ))}
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-4 mb-16">
                <p className="font-bold text-2xl my-4">Featured Spaces</p>
                <div className="gap-4 grid grid-cols-1 md:grid-cols-2 my-4">
                    {spaceData.map((item, index) => (
                        <div>
                            <a href={`space/${item.spaceId}`}>
                                <Card key={index}>
                                    <CardHeader className="absolute z-10 top-24 md:top-12 lg:top-24 flex-col">
                                        <Image
                                            draggable="false"
                                            removeWrapper
                                            className="object-cover z-0 h-20 aspect-square"
                                            src={item.profilePic}
                                        />
                                        <p className="text-white font-bold mb-0 text-2xl mt-1">{item.name}</p>
                                        <p className="text-white/60 font-medium text-large">{item.owner.name}</p>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                        <Image
                                            draggable="false"
                                            removeWrapper
                                            className="object-cover z-0 w-full h-[24rem] md:h-[16rem] lg:h-[24rem] brightness-75"
                                            src={item.backgroundPic}
                                        />
                                    </CardBody>
                                </Card>
                            </a>
                        </div>
                        ))}
                </div>
            </div>
        </div>
    )
        ;
}

export default Home;