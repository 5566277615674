import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faScrewdriverWrench} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export function PageInDevelopment() {
    const { t } = useTranslation();
    return (
        <div style={{textAlign: "center", marginTop: "200px"}}>
            <h1>LABELCLUSTER</h1>
            <FontAwesomeIcon icon={faScrewdriverWrench} style={{fontSize: '100px', margin: '30px 0'}}/>
            <p>{t("development")}</p>
        </div>
    );
}