import {useLocation} from "react-router-dom";

import React, {Component, useEffect, useState} from 'react';
import {Link, Image, Button, ScrollShadow, useDisclosure, Modal, ModalContent, Divider} from "@nextui-org/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGlobe,
    faMessage,
    faPlus,
    faSackDollar,
    faTriangleExclamation,
    faUser
} from "@fortawesome/free-solid-svg-icons";

export function Background({data}) {
        return (
            <div>
                <div className="absolute top-0 -z-10 w-full">
                    <Image
                        draggable="false"
                        removeWrapper
                        radius="none"
                        className="object-cover w-full h-[200px] brightness-50"
                        src={data.backgroundPic}
                    />
                </div>

                <div className="relative z-10 w-full h-[200px]">
                    <div>
                        <div className="absolute left-[30px] top-[100px] text-center md:text-left">
                            <p className="text-white font-bold mb-0 text-5xl mt-1">{data.name}</p>
                            <p className="text-white/60 font-medium text-large">{data.location}</p>
                        </div>
                    </div>
                </div>
            </div>


        )
}

/*
const base = `/${pathname.slice(1).split("/").shift()}`;
 */