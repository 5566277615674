import React, { useState, useEffect  } from "react";
import BlogComponent from "./BlogComponent";
import axios from "axios";
import {Header} from "../../Public/Header";
import {Footer} from "../../Public/Footer";
import {admin_secret, admin_username, api_baseUrl} from "../../../globalVariable";

function Blog() {

  const [pageNum, setPageNum] = useState(0);


  const [blog, setBlog] = useState([
    {
      image: "holder.js/200x200?auto=yes",
      date: "Loading",
      title: "Loading",
      description: "Loading",
    },])

    useEffect(() => {
    let requestUrl = api_baseUrl + "api/token/obtain/";
    let blogUrl = api_baseUrl + "api/blog";

    //Get tokens
    axios
      .post(requestUrl, {
        username: admin_username,
        password: admin_secret,
      })
      .then(function (response) {
        console.log(response.data.access);

        //Get artwork

        axios
          .get(blogUrl, {
            headers: {
              Authorization: "Bearer " + response.data.access,
            },
          })
          .then(function (response) {
            console.log(response.data);
            setBlog(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  console.log(blog)
  return (
      <div>
      <Header/>
          <div className="container mx-auto px-4">
              <BlogComponent
                  data={blog}
              />
              <div style={{maxWidth: '1000px'}}>
                  <div>
                      <div style={{width: '300px', textAlign: 'center'}}>
                          <div className="me-2" aria-label="First group">
                              <div onClick={() => {
                                  setPageNum(0);
                              }}>1</div>
                              <div onClick={() => {
                                  setPageNum(1);
                              }}>2</div>
                              <div onClick={() => {
                                  setPageNum(2);
                              }}>3</div>
                              <div onClick={() => {
                                  setPageNum(3);
                              }}>4</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <Footer/>
      </div>
  );
}

export default Blog;
