import {space, work} from "../../utils/defaultData";
import {Avatar, Image, Link, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from "@nextui-org/react";
import {MusicPlayer} from "./Work/MusicPlayer";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocation, faLocationDot} from "@fortawesome/free-solid-svg-icons";

export function About() {
    return (
        <div>
            <div className="my-8">
                <div className="flex justify-center items-center">
                    <Image
                        draggable="false"
                        removeWrapper
                        className="object-cover opacity-100 h-24 brightness-50 aspect-square"
                        src={space.profilePic}
                    />
                </div>
                <div className="text-center my-4">
                    <p className="font-bold mb-0 text-5xl mt-1">{space.name}</p>
                    <p className="font-medium text-large">{space.location}</p>
                </div>
            </div>
            <div className="px-4">
            <div className="mt-8">
                <div className="col-span-6 md:col-span-8 ">
                    <div className="mb-4">
                        <p className="font-bold text-xl mb-4">About</p>
                        <p className="text-small ">{work.description}</p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-6 md:grid-cols-12 gap-8 md:gap-4">
                <div className="col-span-6 md:col-span-8 ">
                    <div className="mb-4">
                        <p className="font-bold text-xl mb-4">Biography</p>
                        <p className="text-small">{work.content[0].description}</p>
                    </div>
                    <div>
                        <p className="font-bold text-xl mb-4">Team</p>
                        <div className="my-4">
                            {work.content[0].credits.table.map((item, index) => (
                                <div className="flex my-2">
                                    <Avatar src="https://i.pravatar.cc/150?u=a042581f4e29026024d" className="mt-1"/>
                                    <div className="mx-4">
                                        <p>
                                            <Link color="foreground"
                                                  className="font-semibold text-small">{item.name}</Link>
                                        </p>
                                        <p className="text-tiny mr-2 text-foreground-500">{item.credit.join(", ")}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-span-6 md:col-span-4">
                    <p className="font-bold text-xl mb-4">Social</p>
                    <div>
                        <div className="mb-4">
                            {space.social.map((item, index) => (
                                <p><Link color="foreground" className="text-small mr-2"
                                         href={item.link}>{item.platform}</Link></p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-8">
                <p className="font-bold text-large">Tag</p>
                <div>
                    {work.tags.map((item, index) => (
                        <Link color="foreground" className="text-small mr-2">{item}</Link>
                    ))}
                </div>
            </div>
            <div className="my-4">
                <p><Link color="foreground" className="text-small mr-2">Contact {space.name}</Link></p>
                <p><Link color="foreground" className="text-small mr-2">Report {space.name}</Link></p>
            </div>
            <div className="mt-10">
                <p className="text-xs text-black text-opacity-50"><span>Ⓟ</span>{work.content[0].pLine}</p>
                <p className="text-xs text-black text-opacity-50"><span>©</span>{work.content[0].cLine}</p>
            </div>
            </div>
        </div>
    )
}