import React, {useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, CardHeader, Image, Link, Slider} from "@nextui-org/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward, faForward, faHeart, faPlay, faRepeat, faShuffle} from "@fortawesome/free-solid-svg-icons";

export function MusicPlayer({ file,  coverImageUrl, songTitle, songNumber}) {
    const [liked, setLiked] = React.useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [value, setValue] = useState(0); // Slider value
    useEffect(() => {
        // Create a new Audio object whenever the file changes
        const newAudio = new Audio(file);
        audioRef.current = newAudio;

        // Set up event listeners on the new Audio object
        const setAudioData = () => {
            setDuration(Math.round(newAudio.duration));
        };

        const setAudioTime = () => {
            setCurrentTime(Math.round(newAudio.currentTime));
        };

        newAudio.addEventListener('loadedmetadata', setAudioData);
        newAudio.addEventListener('timeupdate', setAudioTime);

        // Cleanup function to remove event listeners when component unmounts or file changes
        return () => {
            newAudio.removeEventListener('loadedmetadata', setAudioData);
            newAudio.removeEventListener('timeupdate', setAudioTime);
        };
    }, [file]);

    const togglePlayPause = () => {
        if (!isPlaying) {
            // Attempt to play and catch any errors
            audioRef.current.play().then(() => {
                setIsPlaying(true);
            }).catch(error => console.error("Error attempting to play audio:", error));
        } else {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    function formatSeconds(seconds) {
        // Round the seconds to the nearest integer in case it's a floating-point number
        seconds = Math.round(seconds);

        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;

        // Pad the minutes and seconds with leading zeros if necessary
        minutes = String(minutes).padStart(2, '0');
        remainingSeconds = String(remainingSeconds).padStart(2, '0');

        return `${minutes}:${remainingSeconds}`;
    }

    const handleSliderChange = (newValue) => {
        setValue(newValue);
        if (audioRef.current) {
            audioRef.current.currentTime = newValue;
        }
    };

    return (
        <div>
            <Card>
                <CardBody className="p-5">
                    <div className="grid grid-cols-6 md:grid-cols-12 gap-8 md:gap-4 items-center justify-center">
                        <div className="relative col-span-6 m-auto md:col-span-4 max-w-[300px] mb-4 md:mb-0">
                            <Image
                                draggable="false"
                                alt="Album cover"
                                className="object-cover"
                                src={coverImageUrl}
                            />
                        </div>
                        <div className="flex flex-col col-span-6 md:col-span-8 mx-8">
                            <div className="flex justify-between items-start">
                                <div className="flex flex-col gap-0">
                                    <p className="text-large font-semibold">{songTitle}</p>
                                    <p className="text-small text-foreground/80">{songNumber} Tracks</p>
                                </div>
                                <Button
                                    isIconOnly
                                    className="text-default-900/60 data-[hover]:bg-foreground/10 -translate-y-2 translate-x-2"
                                    radius="full"
                                    variant="light"
                                    onPress={() => setLiked((v) => !v)}
                                >
                                    <FontAwesomeIcon icon={faHeart}/>
                                </Button>
                            </div>

                            <div className="flex flex-col mt-3 gap-1">
                                <Slider
                                    aria-label="Music progress"
                                    classNames={{
                                        track: "bg-default-500/30",
                                        thumb: "w-2 h-2 after:w-2 after:h-2 after:bg-foreground",
                                    }}
                                    color="foreground"
                                    maxValue={duration}
                                    value={currentTime}
                                    onChange={(value) => {
                                        handleSliderChange(value);
                                    }}
                                    size="sm"
                                />
                                <div className="flex justify-between">
                                    <p className="text-small">{formatSeconds(currentTime)}</p>
                                    <p className="text-small text-foreground/50">{formatSeconds(duration)}</p>
                                </div>
                            </div>

                            <div className="flex w-full items-center justify-center">
                                <Button
                                    isIconOnly
                                    className="data-[hover]:bg-foreground/10"
                                    radius="full"
                                    variant="light"
                                >
                                    <FontAwesomeIcon icon={faRepeat} className="text-black text-opacity-50"/>
                                </Button>
                                <Button
                                    isIconOnly
                                    className="data-[hover]:bg-foreground/10"
                                    radius="full"
                                    variant="light"
                                >
                                    <FontAwesomeIcon icon={faBackward}/>
                                </Button>
                                <Button
                                    onClick={togglePlayPause}
                                    isIconOnly
                                    className="w-[50px] h-[50px] data-[hover]:bg-foreground/10"
                                    radius="full"
                                    variant="light"
                                >
                                    <FontAwesomeIcon icon={faPlay} className="text-2xl"/>
                                </Button>
                                <Button
                                    isIconOnly
                                    className="data-[hover]:bg-foreground/10"
                                    radius="full"
                                    variant="light"
                                >
                                    <FontAwesomeIcon icon={faForward}/>
                                </Button>
                                <Button
                                    isIconOnly
                                    className="data-[hover]:bg-foreground/10"
                                    radius="full"
                                    variant="light"
                                >
                                    <FontAwesomeIcon icon={faShuffle} className="text-black text-opacity-50"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}