import {
    Button,
    Slider,
    Image,
    Link,
    Table,
    TableHeader,
    TableColumn,
    TableBody, TableRow, TableCell
} from "@nextui-org/react";
import React, {useEffect, useRef, useState} from "react";
import { useParams } from 'react-router-dom';
import {MusicPlayer} from "./MusicPlayer";
import {work} from "../../../utils/defaultData";
import axiosInstance from "../../../utils/axiosInstance";


export function WorkPage() {
    let { workId } = useParams();


    const [data, setData] = useState([]);
    useEffect(() => {
        axiosInstance.get(`/work/${workId}`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);
    return (
        <div>
            <div className="my-8">
                <p className="text-3xl font-medium">{data.title}</p>
                <p className="font-semibold text-foreground/80">By
                    {data.artists && data.artists.map((item, index) => (
                        <Link color="foreground" className="ml-1">{item.name}</Link>
                    ))}
                </p>
            </div>
            <MusicPlayer
                tracksList={data.content}
                coverImageUrl={data.coverPicUrl}
            />
            <div className=" px-4">
            <div className="mt-8">
                <div className="col-span-6 md:col-span-8 ">
                    <div className="mb-4">
                        <p className="font-bold text-2xl mb-4">Album</p>
                        <p className="text-small ">{data.description}</p>
                    </div>
                    <p className="font-bold text-small">Tag</p>
                    <div>
                        {data.tags && data.tags.map((item, index) => (
                            <Link color="foreground" className="text-small mr-2">{item}</Link>
                        ))}
                    </div>
                </div>
            </div>
                <div className="my-12">
                    <Table removeWrapper aria-label="Example static collection table">
                        <TableHeader>
                            <TableColumn>#</TableColumn>
                            <TableColumn>Title</TableColumn>
                            <TableColumn>Artist</TableColumn>
                            <TableColumn>Duration</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {data.content && data.content.map((item, index) => (
                                <TableRow key={index} className="rounded-2xl overflow-hidden hover:bg-foreground-100">
                                    <TableCell>{item.sequence}</TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.artists.map((artist, artistIndex) => (
                                        <Link key={artistIndex} color="foreground"
                                              className="text-small mr-2">{artist.name}</Link>
                                    ))}</TableCell>
                                    <TableCell>{item.duration}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <p className="font-bold text-2xl mb-4">Song</p>
                <div className="grid grid-cols-6 md:grid-cols-12 gap-8 md:gap-4">
                    <div className="col-span-6 md:col-span-8 ">
                        <div className="mb-4">
                            <p className="font-semibold text-xl mb-4">Description</p>
                            <p className="text-small">{data.content && data.content[0].description}</p>
                        </div>
                        <div>
                            <p className="font-semibold text-xl mb-4">Lyrics</p>
                            <p className="text-small whitespace-break-spaces">
                                {data.content && data.content[0].lyric}
                            </p>
                        </div>
                    </div>
                    <div className="col-span-6 md:col-span-4">
                        <p className="font-semibold text-xl mb-4">Details</p>
                        <div>
                            <div className="mb-4">
                            <p className="font-bold text-small">Tag</p>
                            <div>
                                {data.content && data.content[0].tags.map((item, index) => (
                                    <Link color="foreground" className="text-small mr-2">{item}</Link>
                                ))}
                            </div>
                        </div>
                        <div className="mb-4">
                            <p className="font-bold text-small">Released by: </p>
                            {data.content && data.content[0].release.map((item, index) => (
                                <Link color="foreground" className="text-small mr-2">{item.toString()}</Link>
                            ))}
                        </div>
                        <p>
                            <span className="font-bold text-small">Released date: </span>
                            <span className="text-small">{data.content && data.content[0].date}</span>
                        </p>
                    </div>
                    <p className="font-semibold text-xl my-4">Credit</p>
                    <div>
                        <div className="mb-4">
                            <div className="my-4">
                                {data.content && data.content[0].credits.content.map((item, index) => (
                                    <div>
                                        <p>
                                            <Link color="foreground"
                                                  className="font-semibold text-small mr-2">{item.name}</Link>@
                                            <Link color="foreground"
                                                  className="font text-small mr-2">{item.affiliation}</Link>
                                        </p>
                                        <p className="text-small mr-2">{item.credit.join(", ")}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="mt-10">
                <p className="text-xs text-black text-opacity-50"><span>Ⓟ</span>{data.content && data.content[0].pLine}</p>
                <p className="text-xs text-black text-opacity-50"><span>©</span>{data.content && data.content[0].cLine}</p>
            </div>
            </div>
        </div>
    )
}