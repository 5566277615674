import React, {useEffect, useRef, useState} from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './css/swiper.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import {Card, CardBody, Image} from "@nextui-org/react";
import axiosInstance from "../../utils/axiosInstance";

export default function CustomSwiper() {
    const progressCircle = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
    };
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    };
    const feature = "demo"
    const [featureData, setFeatureData] = useState([]);
    useEffect(() => {
        axiosInstance.get(`/space/${feature}`)
            .then(response => {
                setFeatureData(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                }}
                pagination={pagination}
                modules={[Autoplay, Pagination, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="mySwiper rounded-2xl overflow-hidden"
            >
                <SwiperSlide>
                    <div className="h-[24rem] md:h-[28rem] lg:h-[36rem] rounded-2xl overflow-hidden">
                        <a href={`space/${feature}`}>
                                <div className="absolute bottom-1 z-10 m-8">
                                    <p className="text-white font-bold text-5xl">{featureData.name}</p>
                                    <p className="text-white font-bold text-xl">By {featureData.owner && featureData.owner.name}</p>
                                </div>
                                    <Image
                                        draggable="false"
                                        removeWrapper
                                        radius="none"
                                        className="object-cover z-0 brightness-75 h-[24rem] md:h-[28rem] lg:h-[36rem]"
                                        src={featureData.backgroundPic}
                                    />
                        </a>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="h-[24rem] md:h-[28rem] lg:h-[36rem] rounded-2xl overflow-hidden">
                        <a href={`space/${feature}`}>
                            <div className="absolute bottom-1 z-10 m-8">
                                <p className="text-white font-bold text-5xl">{featureData.name}</p>
                                <p className="text-white font-bold text-xl">By {featureData.owner && featureData.owner.name}</p>
                            </div>
                            <Image
                                draggable="false"
                                removeWrapper
                                radius="none"
                                className="object-cover z-0 brightness-75 h-[24rem] md:h-[28rem] lg:h-[36rem]"
                                src={featureData.backgroundPic}
                            />
                        </a>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="h-[24rem] md:h-[28rem] lg:h-[36rem] rounded-2xl overflow-hidden relative">
                        <a href="/intro">
                            <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
                                <p className="text-white font-bold text-5xl">What is Labelindie?</p>
                                <p className="text-white font-bold text-xl">By {featureData.owner && featureData.owner.name}</p>
                            </div>
                            <Image
                                draggable="false"
                                removeWrapper
                                radius="none"
                                className="object-cover z-0 brightness-75 h-[24rem] md:h-[28rem] lg:h-[36rem]"
                                src={featureData.backgroundPic}
                            />
                        </a>
                    </div>
                </SwiperSlide>
                <div className="autoplay-progress" slot="container-end">
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                </div>
            </Swiper>
        </>
    );
}