import React from "react";
import "../../Public/css/Header.css";
import {Image} from "@nextui-org/react";


function BlogComponent(props) {
  return (
        <div style={{maxWidth: '1000px'}}>
            {props.data.map((value, index) => {
          return (
            <PostComponent
                key={index}
                id={value.id}
              image={value.image}
                date={value.date}
              title={value.title}
              description={value.intro}
            ></PostComponent>
          );
        })}
        </div>
  );
}

function PostComponent({ id, image, date, title, description}) {
    console.log(image)
    return (
        <a style={{color: 'black', textDecoration: 'none'}} href={'/post/' + id}>
                      <div style={{marginBottom: "30px"}}>
                          <div className="justify-content-md-center" style={{textAlign: 'center',}}>
                              <div>
                                  <Image src={image} rounded fluid/>
                              </div>
                          </div>
                          <div>
                              <p style={{margin: '20px 0 0 0'}} >{date}</p>
                              <h2>{title}</h2>
                                  <p>{description}</p>
                          </div>
                      </div>
                  </a>
    )
}

export default BlogComponent;

