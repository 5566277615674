import {Routes, Route, Navigate, useLocation, useParams} from "react-router-dom";

import React, {Component, useEffect, useState} from 'react';
import {SpaceHeader, Header} from "../Public/Header";
import Home from "./Home";
import Work from "./Work";
import {WorkPage} from "./Work/WorkPage";
import {SpaceFooter} from "../Public/Footer";
import {Background} from "./Public/Background";
import {Menu} from "./Public/Menu";
import {Story} from "./Story";
import {Event} from "./Event";
import {Shop} from "./Shop";
import {Blog} from "./Blog";
import {NFT} from "./NFT";
import {About} from "./About";

function Gateway({data}) {
    return (
        <div>
            <SpaceHeader/>
            <Background data={data}/>
            <Menu/>
            <div className="mx-auto px-4 min-h-[500px] max-w-[1024px]">
                <Routes>
                    <Route path="/work" element={<Work data={data}/>}/>
                    <Route path="/work/:workId" element={<WorkPage data={data}/>}/>
                    <Route path="/story" element={<Story data={data}/>}/>
                    <Route path="/event" element={<Event data={data}/>}/>
                    <Route path="/shop" element={<Shop data={data}/>}/>
                    <Route path="/blog" element={<Blog data={data}/>}/>
                    <Route path="/nft" element={<NFT data={data}/>}/>
                    <Route path="/about" element={<About data={data}/>}/>
                    <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
            </div>
        </div>
    );
}

export default Gateway;