import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export function PageNotFound() {
    const { t } = useTranslation();
    return (
        <div style={{textAlign: "center", marginTop: "200px"}}>
            <h1>LABELCLUSTER</h1>
            <FontAwesomeIcon icon={faTriangleExclamation} style={{fontSize: '100px', margin: '30px 0'}}/>
            <p>{t("404")}</p>

        </div>
    );
}